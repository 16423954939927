import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import axios from "axios"

const ContactForm = () => {
  const { register, handleSubmit, errors } = useForm()
  const [messageSent, setMessageSent] = useState(false)

  const onSubmit = data => {
    axios.post("/.netlify/functions/ses-send-email", {
      name: data.name,
      email: data.email,
      phone: data.phone,
      subject: data.subject,
      message: data.message
    }).then((res) => setMessageSent(true))
  }

  useEffect(() => {
    setTimeout(() => {
      setMessageSent(false)
    }, 10000)
  }, [messageSent])

  const formHasErrors = Object.keys(errors).length === 0 && errors.constructor === Object;

  return (
    <div className="colMd6 colLg5">
      {messageSent && <h2 className="textDefault py1 textXs mb15 backgroundPrimary">Mesajul a fost trimis. Multumim!</h2>}
      {!formHasErrors && !messageSent && <h2 className="textDefault py1 textXs mb15 backgroundDanger">Oops! Te rugam sa te asiguri ca ai completat toate campurile corect si sa mai incerci odata.</h2>}

      <form className="textLeft" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="colMd6">
            <label className="inputLabel">Nume*</label>
            <input
              name="name"
              type="text"
              className={`${errors.name ? 'inputFieldError' : '' } inputField`}
              ref={register({ required: "Required" })}
            />
          </div>
          <div className="colMd6">
            <label className="inputLabel">Email*</label>
            <input
              name="email"
              type="email"
              className={`${errors.email ? 'inputFieldError' : '' } inputField`}
              ref={register({ required: "Required" })}
            />
          </div>
          <div className="colMd6">
            <label className="inputLabel">Telefon</label>
            <input
              name="phone"
              type="number"
              className="inputField"
              ref={register()}
            />
          </div>
          <div className="colMd6">
            <label className="inputLabel">Subiect*</label>
            <input
              name="subject"
              type="text"
              className={`${errors.subject ? 'inputFieldError' : '' } inputField`}
              ref={register({ required: "Required" })}
            />
          </div>
          <div className="colMd12">
            <label className="inputLabel">Mesaj*</label>
            <textarea
              name="message"
              className={`${errors.message ? 'inputFieldError' : '' } inputField`}
              ref={register({ required: "Required" })}
            />
          </div>
        </div>

        <div className="row">
          <div className="colMd12 mb3">
            <label className="inputLabel inputCheckboxWrapper" htmlFor="terms">
              <div className="inputCheckboxContent">
                <input
                  type="checkbox"
                  id="terms"
                  name="terms"
                  ref={register({ required: "Required" })}
                  value="Accepti termenii si conditiile?"
                  className={`${errors.terms ? 'inputFieldError' : '' } inputCheckbox mt05 mr1 `}
                />
                <div className="inputCheckboxMask" />
              </div>
              <span>Accept <a target="_blank" href={"/politica-confidentialitate"}>termenii si conditiile</a></span>
            </label>
          </div>
        </div>
        <button type="submit" className="btn btnPrimary">
          Trimite
        </button>
      </form>
    </div>
  )
}

export default ContactForm
