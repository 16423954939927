import React from "react"
import ContactForm from "./ContactForm"
import { graphql, useStaticQuery } from "gatsby"

const Contact = () => {
  const data = useStaticQuery(graphql`
    query  {
      strapiGeneralSettings {
        googleMaps
      }
    }
  `)

  return (
    <section className="container textCenter py5">
      <div className="row">
        <div className="col">
          <h2 className="sectionTitle">Te asteptam <span>cu drag!</span></h2>
        </div>
      </div>
      <div className="row">
        <div className="colMd6 mb3 mb0md">
          <iframe
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            className="contactMap"
            src={data && data.strapiGeneralSettings.googleMaps}
          />
        </div>
        <ContactForm />
      </div>
    </section>
  )
}

export default Contact
